<template>
  <div>
    <div v-if="fullscreen && showRotationWarning" class="rotationWarning">
      Please rotate your device
    </div>
    <div
      v-if="fullscreen && !showRotationWarning"
      style="position: absolute; right: 0.5rem; top: 0.5rem; z-index: 10000"
      @click="closePopup()"
    >
      <v-btn fab dark small>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-container
      v-if="!fullscreen || !showRotationWarning"
      class="content-standard block-container"
      :class="fullscreen ? 'fullscreen' : ''"
      bg
      fill-height
      grid-list-md
      text-xs-center
      :ref="`container`"
    >
      <v-layout row wrap align-center>
        <v-flex>
          <v-img
            v-if="block.images.length === 1"
            :src="`images/${block.images[0]}`"
            contain
            height="100%"
          />
          <v-carousel
            v-if="block.images.length > 1"
            v-model="model"
            @change="slideViewed"
            :style="`height: ${pdfHeight + carouselFooterHeight / 2}px;`"
          >
            <v-carousel-item v-for="(image, i) in block.images" :key="i">
              <v-img
                :src="`images/${image}`"
                contain
                :height="pdfHeight"
                :style="`margin-top: 0;`"
              />
            </v-carousel-item>
          </v-carousel>
          <div style="text-align: center;">
            <img
              v-for="(image, i) in block.images"
              :key="i"
              :src="`images/${image}`"
              @click="model = i"
              :style="
                `width: ${getCarouselWidth(
                  block.images.length
                )}px; cursor: pointer; margin: 4px; ${
                  i === model ? 'border: 2px solid #eb1600;' : ''
                }`
              "
            />
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import analytics from "../../services/analytics";
export default {
  name: "BlockPdf",
  props: ["block", "fullscreen"],
  data: () => ({
    model: 0,
    pdfHeight: 500,
    carouselFooterHeight: 40,
    showRotationWarning: false,
    errorCounter: 0,
    retrying: false
  }),
  methods: {
    closePopup() {
      this.$hub.$emit("showPdfPopup", null);
    },
    setPdfHeight() {
      if (this.retrying) {
        return;
      }
      try {
        this.showRotationWarning = false;
        if (window.innerWidth < 600 && window.innerWidth < window.innerHeight) {
          this.showRotationWarning = true;
        }
        if (this.fullscreen) {
          this.pdfHeight = window.innerHeight;
        } else {
          this.getCarouselFooterHeight();
          const W = this.$refs.container.offsetWidth * 0.5;
          const CT_HT = this.carouselFooterHeight;
          const HT = W * 0.5 + CT_HT;
          if (this.block.images.length === 2) {
            this.pdfHeight = HT * 1.5;
          } else {
            this.pdfHeight = HT;
          }
        }
      } catch (e) {
        if (this.errorCounter >= 3) {
          this.errorCounter = 0;
          return;
        }
        this.retrying = true;
        this.errorCounter++;
        // console.log(e);
        console.log(
          `container undefined; trying again in 250ms (attempt ${this.errorCounter}/3)`
        );
        setTimeout(() => {
          this.retrying = false;
          this.setPdfHeight();
        }, 250);
      }
    },
    slideViewed() {
      const title = this.block.title.split("&ndash;").join("-");
      const slide_num = this.model + 1;
      analytics.slide(title, slide_num);
      this.$hub.$emit("startScreenSaverCheck");
    },
    getCarouselFooterHeight() {
      if (!this.$refs.container) {
        return 0;
      }
      const CONTROLS = this.$refs.container.querySelector(
        ".v-carousel__controls"
      );
      let ctHt = 0;
      if (CONTROLS) {
        ctHt = CONTROLS.offsetHeight;
      }
      this.carouselFooterHeight = ctHt + 50;
    },
    getCarouselWidth(length) {
      const pageContainer = document.querySelector(".page-container");
      const width = pageContainer ? pageContainer.offsetWidth : 0;
      const pageWidth = (width - 240) / length;
      return pageWidth > 200 ? 200 : pageWidth;
    }
  },
  created() {
    window.addEventListener("resize", this.setPdfHeight);
  },
  mounted() {
    this.slideViewed();
    this.setPdfHeight();
    setTimeout(this.setPdfHeight, 10);
    setTimeout(this.setPdfHeight, 100);
    setTimeout(this.setPdfHeight, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setPdfHeight);
  }
};
</script>

<style lang="scss">
.v-carousel__controls__item {
  margin: 0 4px !important;
}
.dialog-text-mobile .v-window__prev,
.dialog-text-mobile .v-window__next {
  top: 0 !important;
}
.block-container {
  padding: 0 !important;
}
.v-carousel__controls {
  height: initial;
  display: none !important;
}
.fullscreen {
  background-color: #000000ee;
  .v-carousel__controls {
    display: none;
  }
  .v-image {
    margin-top: 0 !important;
  }
}
.rotationWarning {
  width: 100vw;
  height: 100vh;
  background-color: black;
  line-height: 110%;
  padding: 20vh 10px;
  text-align: center;
  font-size: 80px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}
</style>
